import { Box, BoxProps } from "@chakra-ui/react"
import { ReactNode } from "react"

interface Props extends BoxProps {
  children?: ReactNode
}

export const CardBox = ({ children, ...props }: Props) => {
  return (
    <Box p={1} rounded="2xl" bgColor="gray.50" {...props}>
      {children}
    </Box>
  )
}
